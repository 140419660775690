import React from "react";
import { Link } from "react-router-dom";
import ProductCard from "./Product/ProductCard";

function BestSellers({ products }) {
  return (
    <div className="z-50 relative">
      <img
        src="1.jpg"
        alt="Background"
        className="absolute h-full w-full object-cover z-0 transition-opacity duration-1000"
      />
      <div
        className="mx-auto z-10 relative p-12"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
      >
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-12/12 px-4">
            <div className="flex flex-wrap items-center mt-6">
              <h2 className="text-3xl font-bold text-white">Best Sellers</h2>
              <Link to={"/Shop"} className="ml-auto text-white underline">
                View More <i className="fa fa-angle-right"></i>
              </Link>
            </div>
          </div>
          <div className="w-full md:w-12/12 px-4 mt-4">
            <hr className="border-b-1 border-gray-300" />
          </div>
          {/* {products.map((product) => (
            <div
              className="w-full md:w-4/12 px-4 mt-4  transition duration-300 ease-in-out transform hover:translate-x-1 overflow-hidden"
              key={product.productId}
            >
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg">
                <Link to={"/Shop/" + product.productId}>
                  <img
                    alt="..."
                    src={product.productImages}
                    className="w-full align-middle "
                  />
                  <div className="p-6">
                    <h4 className="text-2xl text-pcolor font-semibold">
                      {product.productName}
                    </h4>
                    <p className="mt-1 text-sm text-gray-500">
                      {product.productDescription.substring(0, 100)}...
                    </p>{" "}
                  </div>
                </Link>
              </div>
            </div>
          ))} */}
          <ul className="mt-4 lg:mx-6 grid gap-3 lg:gap-3 grid-cols-2 lg:grid-cols-3 md:grid-cols-3 mb-5">
            {products.map((product) => (
              <div className="group bg-gray-50 p-3 lg:p-8">
                <li
                  key={product.productId}
                  className="mx-1 mt-2 flex justify-center items-center"
                >
                  <Link
                    key={product.productId}
                    to={`/Shop/${product.productId}`}
                    className="group block overflow-hidden"
                  >
                    <ProductCard
                      key={product.productId}
                      name={product.productName}
                      oldPrice={product.productOldPrice}
                      price={product.productPrice}
                      image={product.productImages}
                      className="max-w-full"
                    />
                  </Link>
                </li>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default BestSellers;

import React, {useState} from "react";
import { useCart } from "react-use-cart";
import InputField from "./InputField";
import InputFieldWithButton from "./InputFieldWithButton";
import CheckoutSummary from "./CheckoutSummary";
import ClipLoader from "react-spinners/ClipLoader";
import { GetPromoCode } from "../../helper/Promo Code/CheckPromoCode";

function CheckoutForm({
  name,
  setName,
  email,
  setEmail,
  phone,
  setPhone,
  address,
  promoCode,
  setPromoCode,
  setAddress,
  selectedCountry,
  selectedCity,
  setSelectedCity,
  cities,
  cartTotal,
  shippingPrice,
  totalPrice,
  isEmpty,
  isSubmitting,
  handleCheckoutEvent,
  handleApplyPromoCode,
}) {
  return (
    <form onSubmit={handleCheckoutEvent}>
      <div className="mt-10 px-4 pt-8 lg:mt-0">
        <p className="text-xl font-medium">Payment Details</p>
        <p className="text-gray-400">
          Complete your order by providing your details.
        </p>
        <div>
        {/* add title here personal info */}
          <div className="col-span-2 border-b mt-4 mb-4 text-gray-500 border-gray-300">Personal Info</div>          
          <InputField
            label="Full Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Full Name"
            id="name"
          />

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <InputField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
              id="email"
            />
            <InputField
              label="Phone Number"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              placeholder="Phone Number"
              id="phone"
            />
          </div>

          <div className="col-span-2 border-b mt-4 mb-4 text-gray-500 border-gray-300">Shipping Info</div>          

          <InputField
            label="Address"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            placeholder="Address"
            id="address"
          />

          <div className="grid mt-2 grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
                <label className="sr-only" htmlFor="country">Country</label>
                <select
                    id="country"
                    className="w-full rounded-md bg-gray-300 border-maincolor p-3 text-sm"
                    value="Egypt"
                    required
                    disabled
                >
                    <option value="Egypt" selected >Egypt</option>
                </select>
            </div>

            <div>
              <label className="sr-only" htmlFor="city">City</label>
              <select
                id="country"
                className="w-full rounded-md border-maincolor p-3 text-sm"
                onChange={(e) => setSelectedCity(e.target.value)}
                value={selectedCity}
                required
              >
                <option value="default">City</option>
                {cities.map((city) => {
                  return (
                    <option key={city.id} value={city.name}>
                      {city.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          
          <div className="col-span-2 border-b mt-4 mb-4 text-gray-500 border-gray-300">Promo Code</div>          
          <form>
            <InputFieldWithButton
                label="PromoCode"
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                onButtonClick={handleApplyPromoCode}
                required
                placeholder="Got Promo Code ?"
                id="promoCode"
              />
          </form>

          <CheckoutSummary
            cartTotal={cartTotal}
            shippingPrice={shippingPrice}
            totalPrice={totalPrice}
          />
        </div>

        <button
          type="submit"
          disabled={isEmpty || isSubmitting}
          className={`mt-4 mb-8 w-full rounded-md px-6 py-3 font-medium  transition-colors duration-200 ease-in-out ${
            isEmpty || isSubmitting ? "text-black bg-gray-200 cursor-not-allowed" : "text-white bg-maincolor hover:bg-secondarycolor"
          }`}
        >
          {isSubmitting ? (
            <>
              <ClipLoader
                color="#000000"
                loading={true}
                size={17}
               /> Processing Order...
            </>
          ) : (
            "Place Order"
          )}
        </button>
      </div>
    </form>
  );
}

export default CheckoutForm;
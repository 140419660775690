import React from 'react'

function TestimonalItem({ testimonial }) {
    return (
        <div className="lg:w-1/4 sm:w-1/2 p-4">
            <div className="flex relative">
                <img
                    alt={testimonial.customerSentPic ? testimonial.customerSentPic.title : 'gallery'}
                    className="absolute inset-0 w-full h-full object-cover object-center"
                    src={testimonial.customerSentPic}
                />
                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 transition-opacity duration-500 ease-in-out">
                    <h2 className="tracking-widest text-sm title-font font-medium text-pcolor mb-1">
                        Testimonial
                    </h2>
                    <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                        {testimonial.customerName}
                    </h1>
                    <p className="leading-relaxed">
                        {testimonial.customerMessage}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TestimonalItem
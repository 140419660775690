import React, { useState } from "react";

const InputFieldWithButton = ({ label, type, value, onChange, placeholder, id, required, onButtonClick }) => {
    const [buttonReturnValue, setButtonReturnValue] = useState(0);

    const handleClick = async () => {
        if (buttonReturnValue !== 0) {
            // Refresh the page
            window.location.reload();
        } else if (value.trim() !== '') {
            const result = await onButtonClick();
            setButtonReturnValue(result);
        }
    };

    return (
        <div className="mt-4 relative">
            <label className="sr-only" htmlFor={id}>{label}</label>
            <input
                className={`w-full rounded border outline-none border-white p-3 text-sm 
               focus:border-maincolor focus:ring-2 focus:ring-secondarycolor 
                 transition-colors duration-200 ease-in-out pr-20 
                 ${buttonReturnValue !== 0 ? 'bg-gray-200' : 'bg-white'}`}
                type={type}
                id={id}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                required={required}
                readOnly={buttonReturnValue !== 0}
            />
            <button 
                type="button" 
                className={`absolute right-0 top-1/2 transform -translate-y-1/2 px-6 py-3 text-white rounded-r-md text-sm font-semibold hover:bg-secondarycolor transition-colors duration-200 ease-in-out ${buttonReturnValue !== 0 ? 'bg-maincolor' : 'bg-maincolor'}`} 
                onClick={handleClick}
            >
                {buttonReturnValue !== 0 ? 'Remove' : 'Apply'}
            </button>
        </div>
    );
};

export default InputFieldWithButton;
import React from 'react'

function WhoAreWe() {
  return (
    <section className="text-gray-600 body-font mb-10">
  <div className="container mx-auto flex px-2 lg:px-28 py-14 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <h1 className="title-font sm:text-4xl text-4xl mb-4 font-medium text-pcolor">
        THE ATTIVA STORY
      </h1>
      <p className="mb-8 lg:text-xl leading-relaxed">
      At Attiva, we believe that every woman deserves to feel confident and comfortable while pursuing an active lifestyle. Our passion for fashion meets function, and we're here to empower women to embrace their workouts with style and grace. We focus on modesty, comfort, and chic design, creating sports wear that not only looks fantastic but also supports your active endeavors. Our commitment to crafting high-quality garments in a range of chic colors and designs ensures that you can express your unique style while staying true to your values. Join us in redefining active wear and experience the perfect blend of fashion and function. Elevate your confidence and comfort with Attiva
      </p>
      {/* <div className="flex justify-center">
        <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
          Button
        </button>
        <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">
          Button
        </button>
      </div> */}
    </div>
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
      <img
        className="object-cover object-center rounded"
        alt="hero"
        src="about-section.jpg"
      />
    </div>
  </div>
</section>

  )
}

export default WhoAreWe
import React from "react";
import { Link } from "react-router-dom";

function AboutSection() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <img
            className="object-cover object-center rounded lg:rounded-r-none lg:rounded-l-lg shadow-lg md:rounded-r-none md:rounded-l-lg w-full md:h-full h-96"
            alt="about"
            src="about-section.jpg"
          />
        </div>
        <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
          <h1 className="title-font text-4xl lg:text-4xl mb-4 font-medium text-pcolor">
            About Us
          </h1>
          <p className="mb-8 lg:text-2xl font-light  leading-relaxed">
            We're more than just a sportswear store; 
            we're a community fostering positivity and support.
            By choosing our activewear, you're joining a movement that celebrates the 
            resilience and beauty of every woman. Together, 
            let's embrace a healthier, happier, and more active lifestyle. 
            Thank you for being a part of our empowering journey.
          </p>
          <div className="flex justify-center">
            <Link to={'/Shop'} className="inline-flex text-white bg-maincolor border-0 py-2 px-6 focus:outline-none hover:bg-secondarycolor transition-all duration-500 rounded text-lg">
              Go to Shop
            </Link>
            <Link to={'/about'} className="ml-4 inline-flex text-white bg-secondarycolor border-0 py-2 px-6 focus:outline-none hover:bg-maincolor hover:bg-opacity-50 transition-all duration-500 rounded text-lg">
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;

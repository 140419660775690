import api from '../api';

export async function CheckTestimonals() {
    try {
        const data = await api.get(`/content/items/Testimonal?populate=1`);
        return data.length > 0;
    } catch (err) {
        console.log(err);
        return false;
    }
}
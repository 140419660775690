import React, { useEffect, useState } from 'react'
import HeroSection from '../components/HeroSections/HeroSection'
import Collection from '../components/Product/Collection'
import NewCollection from '../components/Product/NewCollection'
import Divider from '../components/Others/Divider'
import OverlapSection from '../components/AboutUs/OverlapSection'
import { motion } from 'framer-motion'
import { GetProductsByFeature } from '../helper/Homepage/GetProductsByFeature'
import Blog from '../components/Product/Blog'
import { GetBlogPictures } from '../helper/Homepage/GetBlogPictures'
import { useAlert } from 'react-alert'
import Testimonal from '../components/Testimonal/Testimonal'
import { CheckTestimonals } from '../helper/Testimonal/CheckTestimonals'
import BestSellers from '../components/BestSellers'
import { GetAllProducts } from '../helper/GetProducts'
import AboutSection from '../components/AboutUs/AboutSection'

function HomePage() {
  const [products, setProducts] = useState([]);
  const [bestSellers, setBestSellers] = useState([]);
  const [latestCollection, setLatestCollection] = useState([]);
  const [hasTestimonials, setHasTestimonials] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    
    const fetchProducts = async () => {
      try {
        const data = await GetAllProducts();
        setProducts(data);
        const bestSellers = data.filter(product => product.isBestSeller);
        setBestSellers(bestSellers);
        const latestCollection = data.filter(product => product.isLatestCollection);
        setLatestCollection(latestCollection);
      } catch (error) {
        alert.show('Failed to fetch products. Please try again later.', { type: 'error' });
      }
    }

    const fetchTestimonals = async () => {
      try {
        const testimonialsExist = await CheckTestimonals();
        setHasTestimonials(testimonialsExist);
      } catch (error) {
        alert.show('Failed to fetch products. Please try again later.', { type: 'error' });
      }
    }

    fetchProducts();
    fetchTestimonals();
  }, []);

  return (
    <motion.div  initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <HeroSection />
        <br></br>
        <Collection products={latestCollection} title={"Attiva's Latest ✨"} 
          description={"Hot off the press! Shop Attiva's freshest finds."} />
        <BestSellers products={bestSellers} />
        <AboutSection />
        { hasTestimonials && <Testimonal /> }

    </motion.div>
  )
}

export default HomePage
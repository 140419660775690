import React from 'react'
import TestimonalItem from './TestimonalItem'
import { useState, useEffect } from 'react'
import { GetTestimonials } from '../../helper/Testimonal/GetTestimonals';

function Testimonal() {
    // get the data from the backend
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const testimonials = await GetTestimonials();
        setTestimonials(testimonials);
      } catch (error) {
        alert.show('Failed to fetch testimonials. Please try again later.', { type: 'error' });
      }
    }
    fetchTestimonials();
  }, []);

  return (
    <section className="text-gray-600 body-font">
    <div className="container px-5 py-24 mx-auto">
      <div className="flex flex-col text-center w-full mb-20">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-pcolor">
            Our Happy Customers
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            See what others love!  Our Happy Customers showcases real people enjoying the benefits of our products.
        </p>
      </div>
      <div className="flex flex-wrap -m-4">
        {testimonials.map((testimonial, index) => (
          <TestimonalItem key={index} testimonial={testimonial} />
        ))}
    </div>
    </div>
  </section>
  
  )
}

export default Testimonal
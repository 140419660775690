import { UpdateQuantity } from "./UpdateQuantity";
import api from "../api";

export const SubmitOrder = async (userData, items, totalAmount) => {
  let OrderItems = [];

  for (let item of items) {
    const resp = await api.post("/content/item/orderItem", {
      data: {
        Product_Name: item.name,
        Product_Color: item.color,
        Product_Size: item.size,
        Product_Quantity: item.quantity,
      },
    });

    OrderItems.push({
      _model: "orderItem",
      _id: resp._id,
      Product_Name: item.name,
      Product_Color: item.color,
      Product_Size: item.size,
      Product_Quantity: item.quantity,
    });
  }

  const order = {
    Full_Name: userData.name,
    Email: userData.email,
    Phone_Number: userData.phone,
    Address: userData.address,
    Country: userData.country,
    City: userData.city,
    Total_Price: String(totalAmount),
    Orders: OrderItems,
  };

  try {
    console.log("order details: ", JSON.stringify(order));
    await api.post("/content/item/order", {
      data: order,
    });

    for (let item of items) {
      const isUpdated = await UpdateQuantity(
        item.variant.variantId,
        item.quantity
      );
      if (!isUpdated) {
        return false;
      }
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

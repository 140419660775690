import React from 'react';

function CheckoutSummary({ cartTotal, shippingPrice, totalPrice }) {
    return (
        <>
            <div className="mt-6 border-t border-b py-2">
                <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900">Subtotal</p>
                    <p className="font-semibold text-gray-900">{cartTotal} EGP</p>
                </div>
                <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900">Shipping</p>
                    <p className="font-semibold text-gray-900">
                        {shippingPrice === 0 ? "Select a city to calculate shipping" : `${shippingPrice} EGP`}
                    </p>
                </div>
            </div>
            <div className="mt-6 flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">Total</p>
                <p className="text-2xl font-semibold text-gray-900">{totalPrice} EGP</p>
            </div>
        </>
    );
}

export default CheckoutSummary;
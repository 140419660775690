import React from "react";
import PropTypes from "prop-types";

function ReusableHeroSection({ pageName }) {
  return (
    <section className="relative h-96">
      <img
        src="/3.jpg"
        alt="Background"
        className="absolute h-full w-full object-cover z-0"
      />
      <div className="relative h-full bg-black bg-opacity-65 flex items-center justify-center z-10">
        <div className="text-center h-20 flex flex-col justify-center">
          <h1 className="text-pcolor font-extrabold text-3xl xs:text-5xl md:text-6xl">
            <span className="text-white">Welcome to </span>
            <img
              src="/fushica.png"
              alt="Logo"
              className="transform scale-150 ml-6 h-16 mb-2 lg:h-24 lg:mb-3 w-auto inline-block"
            />
          </h1>
          <h2 className="text-gray-200 text-3xl xs:text-4xl md:text-5xl leading-tight mt-4">
            {pageName}
          </h2>
        </div>
      </div>
    </section>
  );
}

ReusableHeroSection.propTypes = {
  pageName: PropTypes.string.isRequired,
};

export default ReusableHeroSection;

import api from '../api';

export async function GetTestimonials() {
    let testimonials = [];
    try {
        const data = await api.get(`/content/items/Testimonal?populate=1`);
        testimonials = data.map((item) => ({
            testimonialId: item._id,
            customerName: item.CustomerName,
            customerMessage: item.CustomerMessage,
            customerSentPic: api.getImageUrl(item.CustomerSentPic)
        }));
    } catch (err) {
        console.log(err);
    }

    return testimonials;
}
import { SubmitOrder } from "./SubmitOrder";

export const handleCheckout = async (
  userData,
  items,
  totalAmount,
  shippingPrice,
  alert,
  emptyCart,
  setIsSubmitting
) => {
  console.log(totalAmount);
  setIsSubmitting(true);
  try {
    const isSuccessful = await SubmitOrder(userData, items, totalAmount);
    if (isSuccessful) {
      try {
        alert.show("Order submitted successfully", { type: "success" });
        setTimeout(() => {
          window.location.href = "/";
          emptyCart();
        }, 2500);
      } catch (error) {
        console.error(error);
      }
    } else {
      alert.show("Failed to submit order", {
        type: "error",
      });
    }
  } catch (error) {
    console.error(error);
    // Handle error
  } finally {
    setIsSubmitting(false);
  }
};

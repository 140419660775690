// import { Link } from "react-router-dom";

// export default function ProductCard(props) {
//   const { name, price, image } = props;
//   return (
//     <div className="">
//         <img
//           src={image}
//           alt=""
//           // className="h-[320px] w-full object-cover transition duration-500 group-hover:scale-105 lg:h-[560px]"
//           // className="h-[320px] w-full object-cover transition duration-500 group-hover:scale-105 lg:h-[540px]"
//           className="h-[260px] w-full object-covertransition duration-300 ease-in-out transform hover:translate-x-1 overflow-hidden hover:border-maincolor  lg:h-[540px]"
//         />
//         <div className="relative pt-2 lg:pt-4 text-center">
//           {name &&
//             <h3 className="text-md lg:text-xl text-gray-700 group-hover:text-maincolor transition-colors duration-200 ease-in-out">
//               {name}
//             </h3>
//           }
//           <p className="text-sm lg:text-md mt-1 lg:mt-2 group-hover:text-gray-600 transition-colors duration-200 ease-in-out">
//             LE {price}.00
//             {/* { price && <span className=" text-gray-900"> {price} EGP </span>} */}
//           </p>
//         </div>
//     </div>
//   );
// }

export default function ProductCard(props) {
  const { name, oldPrice, price, image } = props;

  return (
    <div>
      <div className="flex justify-center items-center ">
        <img
          className=" transition duration-300 ease-in-out transform hover:scale-105 overflow-hidden"
          src={image}
          alt={name}
        />
      </div>
      <div className="mt-4">
        <h3 className="text-md lg:text-xl text-gray-700 group-hover:text-maincolor transition-colors duration-200 ease-in-out">
          {name}
        </h3>
        <div className="flex items-center">
          <p className="text-sm lg:text-md mt-1 lg:mt-2 group-hover:text-gray-600 transition-colors duration-200 ease-in-out">
            LE {price}
          </p>
          {oldPrice && (
            <span className="text-sm lg:text-lg line-through transition duration-300 ease-in-out transform group-hover:scale-105">
              LE {oldPrice}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Cart from "./Cart/Cart";
import { useCart } from "react-use-cart";
import { useLocation } from 'react-router-dom';


export default function SiteHeader({ cartItems }) {
  const [sidecart, setSidecart] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [activeLink, setActiveLink] = useState('/');
  const location = useLocation();

  const { items } = useCart();

  useEffect(() => {
    if (items.length > 0 && !firstLoad) {
      setSidecart(true);
    }
    setFirstLoad(false);
  }, [items.length]);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const showSidecart = () => setSidecart(!sidecart);

  return (
    <>
      <div className={`overlay ${sidecart ? 'active' : ''}`} onClick={showSidecart} />
      <Cart sidecart={sidecart} showSidecart={showSidecart}/>

        <nav id="nav" className="flex z-custom bg-headercolor shadow-lg backdrop-blur-md items-center h-20 justify-between sticky top-0 px-4 lg:px-6">
          <Link to={"/"} className="p-2 w-32 lg:w-32 lg:ml-10 lg:p-0">
            <img id="logo" src="/logo-nobgm.png" className="mx-auto lg:w-35" alt="/"/>
          </Link>
          <div className="flex items-center">
            <NavLink to={"/"} 
              className={`p-2 mr-2 lg:mr-2 hidden lg:block group transition-all duration-300 border-b-2 ${activeLink === '/' ? 'border-white' : 'border-transparent'} hover:border-white`} 
            >
              <h1 className="uppercase text-white">home</h1>
            </NavLink>
            <NavLink to={"/Shop"} 
              className={`p-2 mr-2 lg:mr-2 group transition-all duration-300 border-b-2 ${activeLink === '/Shop' ? 'border-white' : 'border-transparent'} hover:border-white`} 
            >
              <h1 className="uppercase text-white lg:block">SHOP</h1>
            </NavLink>
            <NavLink to={"/about"} 
              className={`p-2 mr-2 lg:mr-2 hidden lg:block group transition-all duration-300 border-b-2 ${activeLink === '/about' ? 'border-white' : 'border-transparent'} hover:border-white`} 
            >
              <h1 className="uppercase text-white ">about</h1>
            </NavLink>
            
            <div className="h-4 w-0.5 bg-white mx-2"></div>
            
            <Link onClick={showSidecart} className="relative mr-2 p-2 lg:mr-10 group transition-all duration-300 border-b-2 border-transparent hover:border-white">
              <h1 className="uppercase mr-2 text-white lg:block">
                Cart
              </h1>
              {items.length > 0 && (
                <span id="cart-count" className={`absolute top-0 right-0 bg-white text-pcolor rounded-full text-xs w-5 h-5 flex items-center justify-center transition-all duration-200 ease-in-out }`}>
                  {items.length}
                </span>
              )}
            </Link>

          </div>
        </nav>
    </>
  );
}
import { TypeAnimation } from 'react-type-animation';

const TypeWriter = () => {
  const fontSize = window.innerWidth < 768 ? '25px' : '45px'; // adjust the values as needed

  return (
    <TypeAnimation
      sequence={[
        "Elevate confidence with Attiva's activewear.",
        1500,
        "Elevate confidence with Attiva's chic designs.",
        1500,
        "Elevate confidence with Attiva's stylish activewear.",
        1500,
        "Elevate confidence with Attiva's trendy activewear.",
        1500
      ]}
      wrapper="span"
      speed={40}
      style={{ fontSize: fontSize, display: 'inline-block', height: '1.2em' }}
      repeat={Infinity}
    />
  );
};

export default TypeWriter;
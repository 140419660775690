import { Link } from "react-router-dom";
import TypeWriter from "./TypeWriter";

function HeroSection() {
  return (
    <section className="relative h-screen transition-all ease-in duration-700">
      <img
        src="/4.jpeg"
        alt="Background"
        className="absolute h-full w-full object-cover z-0 transition-opacity duration-1000"
      />
      <div className="absolute h-full w-full bg-black bg-opacity-30 z-10"></div>
      <div className="relative h-screen flex items-center justify-start p-6 z-20">
        <div className="h-12 text-left">
          <h1 className="text-pcolor font-extrabold text-3xl xs:text-5xl md:text-6xl">
            <span className="text-white">Welcome to </span>
            <img
              src="/fushica.png"
              alt="Logo"
              className="transform scale-150 ml-6 h-20 mb-2 lg:h-24 lg:mb-3 w-auto inline-block"
            />
          </h1>
          <h2 className="text-gray-200 text-2xl xs:text-4xl md:text-5xl leading-tight">
            <TypeWriter />
          </h2>
          <div className="inline-flex flex-grow justify-end">
            <Link
              to={"/Shop"}
              className="p-4 my-8 mx-2 bg-maincolor
                         hover:bg-secondarycolor text-xxs md:text-lg 
                         uppercase tracking-wide text-white shadow-md transition
                         duration-500 rounded-md
                         "
            >
              Buy Now
            </Link>
            <Link
              to={"/about"}
              className="p-4 my-8 mx-4 bg-white hover:bg-secondarycolor text-xxs md:text-lg uppercase tracking-wide text-pcolor shadow-md transition duration-500 rounded-md"
            >
              Who Is Attiva
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;

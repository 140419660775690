import React from "react";
import ProductCard from "./ProductCard";
import { Link } from "react-router-dom";

function Collection(props) {
  const { products, title } = props;
  return (
    <div className="mx-auto max-w-screen-xl px-4 mt-10 mb-12 lg:px-8">
      <header className="text-center">
        <h2 className="text-xl font-bold text-grey-200 sm:text-3xl lg:text-4xl">
          {title}
        </h2>
      </header>
      <ul className="mt-4 lg:mx-6 grid gap-3 lg:gap-3 grid-cols-2 lg:grid-cols-3 md:grid-cols-3 mb-5">
        {products.map((product) => (
          <li
            key={product.productId}
            className="mx-1 mt-2 flex justify-center items-center"
          >
            <Link
              key={product.productId}
              to={`/Shop/${product.productId}`}
              className="group block overflow-hidden"
            >
              <ProductCard
                key={product.productId}
                name={product.productName}
                oldPrice={product.productOldPrice}
                price={product.productPrice}
                image={product.productImages}
                className="max-w-full"
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Collection;

import React from 'react'
import { Link } from 'react-router-dom'

function OverlapSection() {
  return (
    <section>
    <div className="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:h-screen lg:grid-cols-2">
        <div className="relative z-10 lg:py-16">
            <div className="relative h-64 sm:h-80 lg:h-full">
            <img src="/AboutUs/AboutUs.jpeg"
                className="absolute inset-0 h-full w-full object-cover"
                alt="About Us"
            />
            </div>
        </div>
        <div className="relative flex items-center bg-isabelline">
            <span className="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-isabelline" />
            <div className="p-8 sm:p-16 lg:p-24">
            <h2 className="text-2xl font-bold sm:text-3xl">
                What Makes Us Special
            </h2>
            <p className="mt-4 text-gray-600">
            We celebrate diversity and offer a variety of styles that cater to different preferences within the modest fashion spectrum. We believe every woman deserves to feel beautiful and represented.
            </p>
            <Link to={'/about'} className="mt-8 inline-block rounded border border-maincolor bg-maincolor px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-maincolor transition duration-500 ease-in-out">
                Get in Touch
            </Link>
            </div>
        </div>
        </div>
    </div>
    </section>

  )
}

export default OverlapSection
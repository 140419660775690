import React from 'react'

function Announcement(props) {
  const { announcement } = props;
  return (
    <div className="bg-black py-3 text-white font-bold">
        <p className="text-center text-sm">
            {announcement}
        </p>
    </div>

  )
}

export default Announcement
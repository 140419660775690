import React, { useState } from "react";
import { useAlert } from "react-alert";
import api from "../../helper/api";

function LocationSection() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const alert = useAlert();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.post("/content/item/Feedback", {
        data: { Name: name, Email: email, Message: message },
      });
      alert.show("Feedback submitted successfully", { type: "success" });
    } catch (error) {
      console.log(error);
    }

    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5  mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
          <iframe
            width="100%"
            height="100%"
            className="absolute inset-0"
            title="map"
            src="https://www.google.com/maps/embed/v1/place?q=Evolve+180,+New+Cairo+1,+Cairo+Governorate+4726102,+Egypt&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
            style={{ filter: "contrast(1) opacity(0.5)" }}
          />
          <div className="bg-white relative flex flex-wrap py-6 w-full rounded shadow-md">
            <div className="lg:w-1/2 px-6">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                ADDRESS
              </h2>
              <p className="mt-1">
                Evolve 180, New Cairo, Cairo Governorate, Egypt
              </p>
            </div>
            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                EMAIL
              </h2>
              <a
                href="mailto:attivaa@attivaasportswear.net"
                className="text-pcolor leading-relaxed"
              >
                attivaa@attivaasportswear.net
              </a>
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                PHONE
              </h2>
              <p className="leading-relaxed">01116412345</p>
            </div>
          </div>
        </div>

        <form
          onSubmit={handleSubmit}
          className="py-4 lg:w-1/2 md:w-1/2 bg-white flex flex-col px-6 md:ml-auto w-full md:py-8 mt-8 md:mt-0"
        >
          {" "}
          <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
            Feedback
          </h2>
          <p className="leading-relaxed mb-5 text-gray-600">
            Your feedback is important to us. <br></br>
            Please leave your feedback here.
          </p>
          <div className="relative mb-4">
            <label htmlFor="name" className="leading-7 text-sm text-gray-600">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full bg-white rounded border border-gray-300 focus:border-maincolor focus:ring-2 focus:ring-secondarycolor text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-600">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full bg-white rounded border border-gray-300 focus:border-maincolor focus:ring-2 focus:ring-secondarycolor text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="message"
              className="leading-7 text-sm text-gray-600"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full bg-white rounded border border-gray-300 focus:border-maincolor focus:ring-2 focus:ring-secondarycolor h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            />
          </div>
          <button
            type="submit"
            className="text-white bg-maincolor border-0 py-2 px-6 focus:outline-none hover:bg-secondarycolor rounded text-lg"
          >
            Submit Feedback
          </button>
        </form>
      </div>
    </section>
  );
}

export default LocationSection;

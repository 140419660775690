import React, { useState, useEffect } from "react";
import Thumbnail from "../components/ProductDetails/Thumbnail";
import Price from "../components/ProductDetails/Price";
import { GetProductById } from "../helper/GetProducts";
import { useCart } from "react-use-cart";
import BuyNowButton from "../components/ProductDetails/Buttons/BuyNowButton";
import AddToCartButton from "../components/ProductDetails/Buttons/AddToCartButton";
import AddToCartButtonGreyed from "../components/ProductDetails/Buttons/AddToCartButtonGreyed";
import BuyNowButtonGreyed from "../components/ProductDetails/Buttons/BuyNowButtonGreyed";
import QuantityChoice from "../components/ProductDetails/QuantityChoice";
import { useAlert } from "react-alert";
import { motion } from "framer-motion";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIos,
} from "react-icons/md";
import ReusableHeroSection from "../components/HeroSections/ReusableHeroSection";

function ProductDetails() {
  const [product, setProduct] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [maxQuantity, setMaxQuantity] = useState(0);
  const alert = useAlert();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : product.productImages.length - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < product.productImages.length - 1 ? prevIndex + 1 : 0
    );
  };

  const { items, addItem } = useCart();

  let id = window.location.pathname.split("/").pop();

  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  useEffect(() => {
    if (id !== "checkout") {
      const fetchProduct = async () => {
        const product = await GetProductById(id);
        setProduct(product);
        // set the first selected variant with quantity > 0
        const firstVariant =
          product.productVariants &&
          product.productVariants.find(
            (variant) => variant.variantQuantity > 0
          );
        if (firstVariant) {
          setSelectedVariant(firstVariant);
          setSelectedColor(firstVariant.variantColor);
          setSelectedSize(firstVariant.variantSize);
          setMaxQuantity(firstVariant.variantQuantity);
        } else {
          setSelectedVariant({});
          setSelectedColor("");
          setSelectedSize("");
          setMaxQuantity(0);
        }
      };
      fetchProduct();
    }
  }, [id]);

  const handleColorChange = (color) => setSelectedColor(color);
  const handleSizeChange = (event) => setSelectedSize(event.target.value);

  let availableSizes = [];
  const handleQuantityChange = (event) =>
    setSelectedQuantity(event.target.value);
  const increaseQuantity = () =>
    setSelectedQuantity(
      selectedQuantity < maxQuantity ? selectedQuantity + 1 : maxQuantity
    );
  const decreaseQuantity = () =>
    selectedQuantity > 1 ? setSelectedQuantity(selectedQuantity - 1) : "";
  if (selectedColor) {
    availableSizes = product.productVariants
      .filter(
        (variant) =>
          variant.variantColor === selectedColor && variant.variantQuantity > 0
      )
      .map((variant) => variant.variantSize);
  }

  useEffect(() => {
    const variant =
      product.productVariants &&
      product.productVariants.find(
        (variant) =>
          variant.variantColor === selectedColor &&
          variant.variantSize === selectedSize &&
          variant.variantQuantity > 0
      );
    if (variant) {
      setSelectedVariant(variant);
      setMaxQuantity(variant.variantQuantity);
    } else {
      setSelectedVariant(false);
      setMaxQuantity(0);
    }
  }, [selectedColor, selectedSize]);

  const checkIfProductInCart = (showAlert) => {
    const productInCart = items.find(
      (item) =>
        item.id === product.productId &&
        item.color === selectedColor &&
        item.size === selectedSize
    );
    if (productInCart) {
      if (
        productInCart.quantity + selectedQuantity > maxQuantity &&
        showAlert
      ) {
        alert.show("Sorry, Insufficient Stock", { type: "error" });
        return true;
      } else if (
        productInCart.quantity + selectedQuantity > maxQuantity &&
        !showAlert
      ) {
        return true;
      }
    }
    return false;
  };

  const handleBuyNow = () => {
    const quantityExceeds = checkIfProductInCart(false);
    if (quantityExceeds) {
      return;
    }
    const prod = {
      id: product.productId,
      name: product.productName,
      price: product.productPrice,
      color: selectedColor,
      size: selectedSize,
      img: product.productThumbnail[0],
      variant: selectedVariant,
    };
    addItem(prod, selectedQuantity);
    alert.show(`${selectedQuantity}x ${prod.name} added to cart`, {
      type: "success",
    });
  };

  const handleAddToCart = () => {
    const quantityExceeds = checkIfProductInCart(true);
    if (quantityExceeds) {
      return;
    }
    const prod = {
      id: product.productId,
      name: product.productName,
      price: product.productPrice,
      color: selectedColor,
      size: selectedSize,
      img: product.productThumbnail[0],
      variant: selectedVariant,
    };
    addItem(prod, selectedQuantity);
    alert.show(`${selectedQuantity}x ${prod.name} added to cart`, {
      type: "success",
    });
  };

  const changeImage = (image) => {
    let imgElement = document.querySelector(".object-contain");
    imgElement.style.opacity = 0;
    setTimeout(() => {
      imgElement.src = image;
      imgElement.style.opacity = 1;
    }, 300);
  };

  return (
    <motion.div
      className="overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ReusableHeroSection pageName={product.productName} />
      <div className="max-w-6xl px-4 mx-auto py-10 lg:py-12 md:px-6">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4 md:w-1/2 ">
            <div className="flex overflow-hidden">
              <div className="flex flex-col overflow-y-auto h-[400px] lg:h-[670px]">
                {product.productImages &&
                  product.productThumbnail.map((image, index) => (
                    <Thumbnail
                      key={index}
                      url={image}
                      handleClick={() =>
                        changeImage(product.productImages[index])
                      }
                    />
                  ))}
              </div>
              <div className="relative w-full h-full lg:mb-10">
                <img
                  src={
                    product.productImages &&
                    product.productImages[currentImageIndex]
                  }
                  alt={product.productName}
                  className="object-contain w-full h-full image-transition"
                />
                <div className="absolute bottom-3 right-3 flex space-x-2">
                  <button
                    onClick={handlePrevClick}
                    className="p-2 bg-white bg-opacity-50 rounded-full shadow-md hover:bg-maincolor transition-all duration-500"
                  >
                    <MdOutlineArrowBackIos size={25} color="white" />
                  </button>
                  <button
                    onClick={handleNextClick}
                    className="p-2 bg-white bg-opacity-50 rounded-full shadow-md hover:bg-maincolor transition-all duration-500"
                  >
                    <MdOutlineArrowForwardIos size={25} color="white" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 mt-4">
            <div className="lg:pl-20">
              <div className="pb-2 mb-4 border-b border-gray-200">
                {product.isLatestCollection && (
                  <p className="text-2xl font-bold text-black">New</p>
                )}
                {product.productVariants &&
                product.productVariants.some(
                  (variant) => variant.variantQuantity > 0
                ) ? (
                  <p className="text-lg mb-4 font-medium text-green-600">
                    In Stock
                  </p>
                ) : (
                  <p className="text-lg mb-4 font-medium text-rose-600">
                    Out of Stock
                  </p>
                )}
                <h2 className="max-w-xl mt-2 mb-4 text-3xl font-bold uppercase">
                  {product.productName}
                </h2>
                <Price
                  oldPrice={product.productOldPrice}
                  newPrice={product.productPrice}
                />
                <p className="mt-4 text-gray-700">
                  {product.productDescription}
                </p>
              </div>

              {/* Choose Color */}
              <div className="mb-5">
                <h2 className="mb-2 text-xl font-bold">Choose Color</h2>
                <div className="flex flex-wrap -mb-2">
                  {product.productVariants &&
                    [
                      ...new Set(
                        product.productVariants.map((item) => item.variantColor)
                      ),
                    ].map((color, index) => {
                      const variant = product.productVariants.find(
                        (v) => v.variantColor === color
                      );
                      return (
                        <label
                          key={index}
                          htmlFor={variant.variantColor}
                          className={`flex justify-center items-center rounded-full px-2 py-2 mr-3 border w-1/8 hover:border-maincolor hover:text-maincolor ${
                            selectedColor === variant.variantColor
                              ? "border-maincolor text-maincolor"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            id={variant.variantColor}
                            name="color"
                            value={variant.variantColor}
                            checked={selectedColor === variant.variantColor}
                            onChange={() =>
                              handleColorChange(variant.variantColor)
                            }
                            className="hidden"
                          />
                          <div
                            style={{
                              backgroundColor: variant.variantColorCode,
                            }}
                            className="w-6 h-6 rounded-full inline-block"
                          />
                        </label>
                      );
                    })}
                </div>
              </div>

              {/* Choose Size */}
              <div className="pb-6 mb-8 border-b border-gray-300">
                <h2 className="mb-2 text-xl font-bold">Choose Size</h2>
                <div className="flex flex-wrap -mb-2">
                  {availableSizes.length > 0 ? (
                    availableSizes.map((variantSize) => (
                      <label
                        key={variantSize}
                        htmlFor={variantSize}
                        className={`flex justify-center text-md items-center rounded-full mr-3 border 
                                  hover:border-maincolor hover:text-maincolor min-w-10 h-10 p-2 box-border
                                  ${selectedSize === variantSize ? "border-maincolor text-maincolor" : ""}`}>
                        <input
                          type="radio"
                          id={variantSize}
                          name="size"
                          value={variantSize}
                          checked={selectedSize === variantSize}
                          onChange={handleSizeChange}
                          className="hidden"
                        />
                        {
                            (() => {
                                switch (variantSize) {
                                    case 'XSmall':
                                        return variantSize.slice(0, 2).toUpperCase();
                                    case 'XXLarge':
                                        return variantSize.slice(0, 2).toUpperCase();
                                    case 'XLarge':
                                        return variantSize.slice(0, 1).toUpperCase();
                                    default:
                                        return variantSize.charAt(0).toUpperCase();
                                }
                            })()
                        }
                      </label>
                    ))
                  ) : (
                    <label className="rounded-full px-3 py-2 mb-2 mr-2 border w-1/8 border-gray-400 text-gray-400">
                      No Sizes Available
                    </label>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap items-center ">
                <QuantityChoice
                  decreaseQuantity={decreaseQuantity}
                  selectedQuantity={selectedQuantity}
                  handleQuantityChange={handleQuantityChange}
                  maxQuantity={maxQuantity}
                  increaseQuantity={increaseQuantity}
                />

                <div className="mb-4 mr-4 lg:mb-0">
                  {selectedVariant.variantQuantity > 0 ? (
                    <BuyNowButton handleBuyNow={handleBuyNow} />
                  ) : (
                    <BuyNowButtonGreyed />
                  )}
                </div>
                <div className="mb-4 mr-4 lg:mb-0">
                  {selectedVariant.variantQuantity > 0 ? (
                    <AddToCartButton handleAddToCart={handleAddToCart} />
                  ) : (
                    <AddToCartButtonGreyed />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {product.productSizeChart && (
        <div className="max-w-6xl px-4 mx-auto py-4 lg:py-2 md:px-6">
          <h2 className="mb-4 text-xl font-semibold">Size Chart</h2>
          <div className="mb-4 border-b border-gray-300"></div>
          <img
            src={product.productSizeChart}
            alt="washing instructions"
            className="object-contain w-1/2 h-1/2 mx-auto"
          />
        </div>
      )}

      <div className="max-w-6xl px-4 mx-auto py-4 lg:py-4 md:px-6">
        <h2 className="mb-4 text-xl font-semibold">Washing Instructions</h2>
        <div className="mb-4 border-b border-gray-300"></div>
        <ul className="list-disc list-inside text-gray-700">
          <li>Machine wash cotton items in cold or warm water</li>
          <li>Do not use hot water as it may shrink the cotton</li>
          <li>Use a normal wash cycle and regular detergent</li>
          <li>Turn them upside down when ironing</li>
        </ul>
      </div>

      <div className="max-w-6xl px-4 mx-auto py-4 lg:py-4 md:px-6">
        <h2 className="mb-4 text-xl font-semibold">Shipping Policy</h2>
        <div className="mb-4 border-b border-gray-300"></div>
        <ul className="list-disc list-inside text-gray-700">
          <li>
            Our team is doing their best to deliver your order as soon as
            possible. You should be expecting your order after confirming it
            within 5-7 working days.
          </li>
        </ul>
      </div>

      <div className="max-w-6xl px-4 mx-auto py-4 lg:py-4 md:px-6">
        <h2 className="mb-4 text-xl font-semibold">Exchange & Refund Policy</h2>
        <div className="mb-4 border-b border-gray-300"></div>
        <ul className="list-disc list-inside p-0 text-gray-700">
          <li>
            An item can be refunded while paying the shipping fees only to the
            courier. An item can be exchanged with another different item or
            another size with additional shipping fees. Refunded items can't be
            used and must stay in the same form they have been recieved.
          </li>
        </ul>
      </div>
    </motion.div>
  );
}

export default ProductDetails;

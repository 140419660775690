/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export default function Thumbnail(props) {
    const { url, handleClick, selected } = props;
    return (
        <div className="p-1 mr-4 justify-center items-center">
            <a onClick={handleClick} className={`block border-2 rounded-md ${selected ? 'border-maincolor' : 'border-secondarycolor'} hover:border-maincolor 
            transition duration-300 ease-in-out transform hover:translate-x-1 overflow-hidden
             `}>
              <img
                src={url}
                alt=""
                className="object-cover w-16 h-28 lg:h-28 "
              />
            </a>
        </div>
    )
} 
import React from 'react'

export default function OrderSummaryCard(props) {
    const { item } = props;
  return (
    <div className="flex flex-col rounded-md bg-white sm:flex-row">
        <img
        className="m-2 h-20 w-20 rounded-md border object-cover object-center"
        src={item.img}
        alt=""
        />
        <div className="flex w-full flex-col px-2 py-2">
          <span className="m-0 text-lg">
              {item.name} x{item.quantity}
          </span>
          <span className="m-0 text-sm text-gray-400">{item.size}</span>
          <p className="text-md">{item.price} EGP</p>
        </div>
    </div>
  )
}
